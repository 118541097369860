﻿@use "config" as *;
@use "mixins" as *;

// clearfix
.clearfix:after
{
	@include critical
	{
		clear: both;
		content: "";
		display: block;
		font-size: 0;
		height: 0;
		visibility: hidden;
	}
}

* html .clearfix
{
	@include critical
	{
		height: 1%;
	}
}
