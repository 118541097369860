@use "config" as *;
@use "mixins" as *;
@use "fixes";

// hide
.no-print, .hide, .bx-controls, .bx-clone, hr
{
	display: none !important;
}

// bxSlider
.bx-wrapper, .bx-viewport
{
	height: auto !important;
	width: auto !important;
}

// reset
html, body
{
	color: $colorBlack;
	font-family: Helvetica, Arial, sans-serif;
	@include font-size();
	@include line-height();
	margin: 0 auto;
	padding: 0;
	max-width: $maxPageWidth;
}

// links
a
{
	color: $colorBlack;
	text-decoration: none;
}

// headers
h1, h2, h3, h4, h5, h6
{
	@include font-size();
	@include line-height();
	font-weight: bold;
	margin: 0;
	padding: 0 0 15px 0;
}

h1
{
	@include font-size(32px);
	@include line-height(40px);
}

h2
{
	@include font-size(28px);
	@include line-height(36px);
}

h3
{
	@include font-size(24px);
	@include line-height(32px);
}

h4
{
	@include font-size(20px);
	@include line-height(28px);
	padding-bottom: 10px;
}

h5, h6
{
	padding-bottom: 5px;
}

// content
p
{
	margin: 0;
	padding: 0 0 15px 0;
}

ul, ol
{
	margin: 0;
	padding: 0 0 10px 25px;
	li
	{
		margin: 0;
		padding: 0 0 5px 0;
	}
	ul, ol
	{
		margin-bottom: -5px;
		padding: 5px 0 0 25px;
	}
}

ul
{
	list-style: disc;
}

ol
{
	ol
	{
		list-style: lower-alpha;
		ol
		{
			list-style: lower-roman;
		}
	}
}

dl
{
	margin: 0;
	padding: 0 0 10px 0;
	dt
	{
		font-weight: bold;
		margin: 0;
		padding: 0 0 5px 0;
	}
	dd
	{
		margin: 0;
		padding: 0 0 5px 0;
	}
}

picture, img
{
	display: block;
	height: auto;
	margin: 0 auto 15px auto;
	padding: 0;
	width: auto;
	max-width: 100%;
	p &
	{
		margin-bottom: 0;
	}
}

table
{
	border: 0;
	border-collapse: collapse;
	font-size: 14px;
	line-height: 22px;
	margin: 0 0 15px 0;
	padding: 0;
	width: 100%;
	th, td
	{
		margin: 0;
		padding: 5px;
	}
	tbody
	{
		th, td
		{
			border-top: 1px solid $colorDivider;
		}
	}
}

// cms
.cms
{
	blockquote
	{
		@include font-size(18px);
		font-weight: bold;
		@include line-height(26px);
		margin: 0 auto;
		padding: 0 0 15px 0;
		p
		{
			padding-bottom: 10px;
			&:last-child
			{
				padding-bottom: 0;
			}
		}
		.quote-author
		{
			color: $colorGrey;
			@include font-size(14px);
			font-weight: normal;
			@include line-height(22px);
			a
			{
				color: $colorGrey;
			}
		}
	}
	.button
	{
		&:not(a)
		{
			display: block;
		}
	}
	a.button, .button a, a .button
	{
		border: 2px solid $colorBlack;
		display: inline-block;
		font-weight: bold;
		margin: 0;
		padding: 5px 15px;
	}
}

// print grid
.print-grid
{
	font-size: 0;
	line-height: 0;
	margin: -10px 0 0 -10px;
	padding: 0 0 15px 0;
	&__col
	{
		@include box-sizing();
		display: inline-block;
		@include font-size();
		@include line-height();
		margin: 0;
		padding: 10px 0 15px 10px;
		vertical-align: top;
		width: 100%;
		&--2
		{
			width: 50%;
		}
		&--3
		{
			width: 33.3333%;
		}
		&--4
		{
			width: 25%;
		}
		&--5
		{
			width: 20%;
		}
	}
}


// LAYOUT

// header
.l-header
{
	border-bottom: 1px solid $colorBlack;
	margin-bottom: 15px;
	padding-bottom: 15px;
	&__logo
	{
		display: block;
		margin: 0 auto;
		width: 150px;
		> svg
		{
			display: block;
			height: auto;
			width: 100%;
		}
	}
}

// footer
.l-footer
{
	border-top: 1px solid $colorBlack;
	color: $colorGrey;
	@include font-size(12px);
	@include line-height(18px);
	margin-top: 15px;
	padding-top: 15px;
}


// MODULES

// block
.m-block
{
	&__title, &__content
	{
		text-align: center;
	}
	&:not(:last-child)
	{
		border-bottom: 1px solid $colorDivider;
		margin-bottom: 15px;
		padding-bottom: 15px;
	}
}

